// import detectEthereumProvider from '@metamask/detect-provider'
// import Web3 from "web3"
// import { context, dispatch, provider } from "@/utils/crypto/wallet_connect";

import WalletLink from "walletlink";
import infura from "./settings/infura";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";


let context

const getNetwork = () => {


    if (window.ethereum.networkVersion === '1') return 'mainnet'
    if (window.ethereum.networkVersion === '3') return 'ropsten'
    if (window.ethereum.networkVersion === '4') return 'rinkeby'
    if (window.ethereum.networkVersion === '5') return 'goerli'
    if (window.ethereum.networkVersion === '4002') return 'fantomTest'
    if (window.$defaultNetwork === 1) return 'mainnet'
    if (window.$defaultNetwork === 3) return 'ropsten'
    if (window.$defaultNetwork === 4) return 'rinkeby'
    if (window.$defaultNetwork === 5) return 'goerli'
    if (window.$defaultNetwork === 4002) return 'fantomTest'

}

const setNetwork = async () => {
    let $chainId = await context.eth.getChainId();
    console.log($chainId);
    window.$defaultNetwork = $chainId;
}


//Metamask

const connectMetaMask = async (onErr) => {
    try {
        let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        window.connected = accounts.length > 0 && window.ethereum.isConnected
        context = new Web3(window.ethereum);
        await setNetwork();
        return true;
    } catch (e) {
        if (onErr) onErr(e)
        return false
    }
}


const connectCoinBase = async (defaultNetwork) => {
    // const infuraId = infura.getKey(defaultNetwork);
    const infuraUrl = infura.getUrl(defaultNetwork);
    const infuraNetworkId = infura.getNetworkId(defaultNetwork);


    try {
        const walletLink = new WalletLink({ appName: 'PhotoChromic' });
        let provider = walletLink.makeWeb3Provider(infuraUrl, infuraNetworkId);
        await provider.enable();
        if (provider) {
            window.ethereum = provider
            context = new Web3(provider);
            await setNetwork();
            return true
        }
    } catch (error) {
        // reject(error);
        console.log(error);
    }
    // wallet.provider = type;

    return false
}


const connectWalletConnect = async (defaultNetwork) => {
    const infuraId = infura.getKey(defaultNetwork);
    let chainId = infura.getNetworkId(defaultNetwork);
    try {
        let provider = new WalletConnectProvider({ infuraId, chainId });
        await provider.enable();
        window.ethereum = provider
        context = new Web3(provider);
        await setNetwork();
        return true
    } catch (error) {
        console.log(error);
    }
    return false
}


const connected = async () => {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        return accounts.length > 0;
    } catch (e) {
        return false;
    }
}

const getAccount = async () => {
    let isConnected = await connected();
    if (isConnected) {
        let accs = await window.ethereum.request({ method: 'eth_accounts' });

        let full = accs[0];
        let short = '';
        if (full) {
            let prefix = full.substr(0, 5)
            let suffix = full.substr((full.length - 4), 4)
            short = prefix + '...' + suffix;
        }
        return { full, short }
    }
    return null;

}

const getEthBalance = async () => {
    let account = await getAccount();
    if (account) {
        return context.eth.getBalance(account.full);
    }
    return 0;

}

const signOut = () => {

}


export default {
    connectCoinBase, connectMetaMask, connectWalletConnect, connected, getAccount, getNetwork, signOut, context, getEthBalance
}