
const infura = {
    ropsten: {
        name: 'Ropsten Test',
        key: '500f2e37d1de4412b5832f02a31b08c6',
        url: 'https://ropsten.infura.io/v3/500f2e37d1de4412b5832f02a31b08c6',
        networkId: 3,
    },
    rinkeby: {
        name: 'Rinkeby API',
        key: '3ce0bbaf0e004a13b023013aa17ea3b2',
        url: 'https://rinkeby.infura.io/v3/3ce0bbaf0e004a13b023013aa17ea3b2',
        networkId: 4,
    },
    mainnet: {
        name: 'Photochromic',
        key: '443af4569dfc4b8aa78cda241504c5c8',
        url: 'https://mainnet.infura.io/v3/443af4569dfc4b8aa78cda241504c5c8',
        networkId: 1,
    }
}
const getKey = (network) => infura[network]['key']
const getName = (network) => infura[network]['name']
const getUrl = (network) => infura[network]['url']
const getNetworkId = (network) => infura[network]['networkId']


export default {
    getKey,
    getName,
    getUrl,
    getNetworkId,
}